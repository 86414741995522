<template>
  <div class="news box-bg">
    <div class="tit btw">
      <del-nav :name="'新闻公告'"></del-nav>
      <el-form :inline="true" :model="formInline" class="sou" label-width="100">
        <el-form-item label="">
          <el-input
            v-model="formInline.user"
            placeholder="请输入标题/关键字"
            clearable
            size="medium"
            @clear='onSubmit'
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" @click="onSubmit"
            >搜索</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="news-con" v-loading="loading">
      <div
        class="list"
        v-for="(item, index) in list.rows"
        :key="index"
        @click="del(item.infoid)"
      >
        <div class="btw-l">
          <div class="title">
            {{ item.title }}
          </div>
          <div class="time">{{ item.infodate }}</div>
        </div>
        <div class="js">{{ item.haveheadnews }}</div>
      </div>
      <div class="zanwu" v-if="list.rows&&list.rows.length==0 ">
        暂无数据
      </div>
    </div>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="list.pagesize"
      layout="total,  prev, pager, next, jumper"
      :total="list.total"
    >
    </el-pagination>
  </div>
</template>

<script>
import DelNav from "@components/my-delnav";
export default {
  name: "news",
  components: {
    DelNav,
  },
  props: ["list",'loading'],
  data() {
    return {
      formInline: {
        user: "",
      },
      currentPage: 1,
    };
  },
  methods: {
    // 搜索
    onSubmit() {
      console.log(this.formInline, "submit!");
       this.$emit('sou',this.formInline.user)
    },
    // 分页
    handleCurrentChange(val) {
       console.log(`当前页: ${val}`);
      this.$emit('page',val,)
    },
    // 详情
    del(val) {
      this.$router.push({
        path: "/notice/del",
        query: {
          id: val,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.news.box-bg {
  padding: 0;
}
.news-con {
  padding: 5px 25px 6px;
  min-height: 400px;
}
.tit {
  text-align: left;
  padding: 15px 20px 10px;
  border-bottom: 1px solid @border-color;
}

.sou {
  .el-form-item {
    margin-bottom: 0;
  }
}
.list:last-child {
  border-bottom: none;
}
.list {
  margin-top: 10px;
  font-size: 14px;
  cursor: pointer;
  border-bottom: 1px solid @border-color;
  padding-bottom: 10px;
  .title {
    font-size: 15px;
    font-weight: bold;
    color: #333;
    text-align: left;
    line-height: 30px;
    width: 720px;
    margin-bottom: 5px;
  }
  &:hover {
    .title {
      color: @bule;
    }
  }
  .js {
    color: #919191;
  }
  .time {
    color: #919191;
    width: 125px;
    line-height: 30px;
  }
}

.more {
  text-align: right;
  color: @bule;
  cursor: pointer;
  font-size: 14px;
  line-height: 30px;
  margin-top: 10px;
}
.el-pagination {
  padding: 50px 0 30px;
  text-align: center;
}
</style>
