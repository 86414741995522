<template>
  <div class="conr">
    <div class="conr-bot box-bg ">
      <el-menu
      :default-active="active"
      active-text-color="#fff"
      class="el-menu-vertical-demo"
       v-for="item in category" :key="item.typecode"
     >
      <el-submenu :index="item.typecode"  v-if="item.children">
        <template slot="title" >
          <i class="el-icon-folder"></i>
          <span>{{item.typename}}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item  :index="item.typecode"  @click="getcate(item.typecode,item.typecode)">全部</el-menu-item>
          <el-menu-item v-for="xitem in item.children" :key="xitem.typecode" :index="xitem.typecode"  @click="getcate(item.typecode,xitem.typecode)">{{xitem.typename}}</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-menu-item :index="item.typecode"  @click="getcate(item.typecode,item.typecode)" v-else>
        <i class="el-icon-folder"></i>
        <span slot="title">{{item.typename}}</span>
      </el-menu-item>
    </el-menu>
    
    </div>
  </div>
</template>

<script>

export default {
  name: "conr",
  props:['category'],
  data() {
    return {
      active:'' 
    };
  },
  watch: {
    category(val){
       this.active= val[0].typecode
    }
    
  },
  mounted () {

  },
  created() {
    console.log(this.category);
     this.active= this.category.length>0?this.category[0].typecode:''
  },
  methods: {
    getcate(type,val){
      this.active = val
      this.$emit('getcate',type,val)
    },
  },
};
</script>

<style  lang="less"  scoped>
.conr {
  width: 250px;
  .el-menu{
    border-right: none;
  }
  .el-menu-item.is-active{
    background: @bule;
    border-radius: 5px;
  }
  .el-menu-item{
    height: 45px;
    line-height: 45px;
    margin-top: 5px;
  }
}
.conr-bot{
 padding-bottom: 20px;
 height: calc(100% - 50px);
 
}
.tit{
  position: relative;
  font-size: 16px;
  color: #333333;
  padding-left: 15px;
  text-align: left;
   margin: 5px 0;
   font-weight: bold;
}
.tit::before{
  content: '';
  background: @bule;
  width: 5px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 2px;
 
}
.news{
  .item{
    text-align: left;
    line-height: 30px;
    font-size: 14px;
    cursor: pointer;
    &:hover{
      color: @bule;
    }
  }
}

</style>