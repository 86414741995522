<template>
  <div class="delnav">
    <el-breadcrumb separator-class="el-icon-arrow-right"  class="pagetop">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{name}}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "delnav",
  props:['name'],
  data() {
    return {
     
    };
  },
  methods: {
    serherf(val) {
      console.log(val,'serherf');
    },
  },
};
</script>

<style lang="less" scoped>

</style>