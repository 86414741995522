<template>
  <div class="con btw-l mywidth">
     <list-nav :category='category' @getcate='getcate'></list-nav>
    <div class="conl">
      <conl-news :list='list' :loading='loading'  @page='getpage' @sou='getsou'></conl-news>
      <img class="adv" src="@assets/img/adv.jpg" alt="" srcset="" />
    </div>
   
  </div>
</template>

<script>
import { list, category } from "@/api/notice.js";
import ConlNews from "./com/list-news";
import ListNav from "@components/list-nav";
export default {
  name: "Notice",
  components: {
    ConlNews,
    ListNav,
  },
  data() {
    return {
      list: [],
      category: [],
      pagedata: {
        sortId: "100100",
        sortorder: "desc", //排序规则
        sortname: "infodate", //排序字段
        pageindex: 1,
        pagesize: 10,
        announcement: "",
        caption: "",
        detail: "",
      },
      parentid: 100,
      loading:true
    };
  },
  mounted() {
    
    this.getcategory();
  },
  methods: {
    getlist() {
      this.loading = true
      list(this.pagedata).then((data) => {
        this.list = data;
        this.list.pagesize = this.pagedata.pagesize
        this.loading = false
      });
    },
    getcategory() {
      let params = {
        pId:this.parentid
      };
      category(params).then((data) => {
        this.category = data.tdata;
        this.pagedata.sortId = data.tdata?data.tdata[0].typecode:''
        this.pagedata.categoryid = data.tdata?data.tdata[0].typecode:''
        this.getlist();
      });
    },
    // 获取类别
    getcate(sortId,categoryid){
      this.pagedata.sortId = sortId
      this.pagedata.categoryid = categoryid
      this.pagedata.pageindex = 1
      this.getlist();
    },
    // 分页
    getpage(val){
       this.pagedata.pageindex = val
       this.getlist();
    },
    getsou(val){
      this.pagedata.pageindex = 1
       this.pagedata.title = val
       this.getlist();
    }
  },
};
</script>

<style lang="less" scoped>
.con {
  z-index: 10;
  margin-top: 20px;
  position: relative;
  .conl {
    width: 910px;
  }
  .adv {
    margin: 15px auto;
    width: 100%;
  }
}
</style>